(<template>
  <section class="action-buttons">
    <div class="action-buttons__wrapper">
      <button v-if="config.showCopyBtn"
              class="sk-btn sk-btn--default action-buttons__btn"
              @click="copyProject">{{ $gettext('Copy') }}</button>
      <button v-if="config.showCancelBtn"
              class="sk-btn sk-btn--white action-buttons__btn"
              @click="openCancelProjectModal">{{ $gettext('Cancel') }}</button>
      <!--      <button class="sk-btn sk-btn&#45;&#45;white action-buttons__btn"-->
      <!--              @click="openReplacementConfirmationModal">{{ $pgettext('translation', 'Download Assignment Info') }}</button>-->
    </div>
  </section>
</template>)

<script>
  import {mapGetters} from 'vuex';
  import JobActionsPrototype from '@/prototypes/JobActionsPrototype';

  export default {
    extends: JobActionsPrototype,
    props: {
      config: {
        type: Object,
        required: true,
        default() {
          return {
            showEditBtn: false,
            showCancelBtn: false,
            showReplacementBtn: false,
            showCopyBtn: false,
          };
        }
      }
    },
    computed: {
      ...mapGetters('UserInfoStore', [
        'userIsNotInterpreter',
      ]),
      ...mapGetters('OneProjectStore', [
        'project'
      ])
    },
    methods: {
      openCancelProjectModal() {
        const message = this.$pgettext('translation', 'You can cancel your translation request at any time, however if we have already begun the translation process - you will be invoiced. The actual amount depends on the stage of the project. <br><br> Please contact your project manager to know if you will be invoiced for cancellation.');
        const title = this.$pgettext('translation', 'Are you sure?');
        this.$store.commit('ModalStore/setModal', {
          component: 'cancel-project',
          classes: ['sk-modal--new'],
          data: {
            context: this,
            title,
            message
          }
        });
      },
      copyProject() {
        const routeObj = {
          name: 'BuyerPostTranslationProject',
          query: {
            copy: this.project.id
          }
        };

        window.open(this.$router.resolve(this.$makeRoute(routeObj)).href, '_blank');
      },
    }
  };
</script>

<style scoped>
  .action-buttons__wrapper {
    display: flex;
    flex-wrap: wrap;
    width: calc(100% + 20px);
    height: calc(100% + 20px);
    margin: -10px;
  }

  .action-buttons__btn {
    width: auto;
    min-width: 110px;
    margin: 10px;
    padding: 0 15px;
  }

  .action-buttons__btn-wrapper .action-buttons__btn {
    margin: 0;
  }

  @media (max-width: 550px) {
    .action-buttons__btn {
      flex-grow: 1;
    }
  }
</style>
